<template>
  <v-container fluid class="px-0">
    <MyDialog ref="detailsModal" @onConfirm="deleteItem" />
    <products-modal
      ref="productsModal"
      :selectedData="selectedData"
      @GetList="GetList"
      :isAdmin="true"
    />
    <MyDataTable
      title="فایل های موجود"
      subtitle="مدیریت فایل"
      addtitle="ایجاد فایل جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      @onPageChanged="GetList"
      editText=""
      deleteText=""
      class="mt-n13 moholand-shadow"
    >
      <template #headerTag>
        <v-btn
          v-if="pathParams.conditions.status == 0"
          dark
          class="mx-2"
          color="primary darken-2"
          @click="getSpecificList(undefined)"
        >
          نمایش همه فایل ها
        </v-btn>
        <v-btn
          v-else
          dark
          class="mx-2"
          color="purple darken-2"
          @click="getSpecificList(0)"
        >
          نمایش فایل های در حال بررسی
        </v-btn>
      </template>
      <template #topContentTag>
        <v-row align="center" justify="center" class="mb-2 px-4">
          <v-col md="10" cols="12">
            <v-form-base
              :col="{ cols: 12, md: 4 }"
              :model="model"
              :schema="schema"
            />
          </v-col>
          <v-col cols="12" md>
            <v-btn color="primary" block @click="search">
              جستجو
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template #middleButtonTag="{ row }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              dark
              small
              color="black"
              v-bind="attrs"
              v-on="on"
              @click="onDetailsClick(row)"
            >
              <v-icon small dark>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span dir="ltr">{{ "moholand.com/products/" + row.slug }}</span>
        </v-tooltip>
      </template>
      <template #contentTag="{ row }">
        <td>{{ row.name }}</td>
        <td>
          {{
            row.author && row.author.first_name && row.author.last_name
              ? row.author.first_name + " " + row.author.last_name
              : ""
          }}
        </td>
        <td>
          {{ row.price ? Intl.NumberFormat().format(row.price) : 0 }}
          <br />
          <small>تومان</small>
        </td>
        <td>{{ row.viewed }}</td>
        <td class="text-center">
          <v-chip v-if="row.status == 0" color="yellow darken-3" dark x-small>
            در حال بررسی
          </v-chip>
          <v-chip v-else-if="row.status == 1" color="success" dark x-small>
            تایید شده
          </v-chip>
          <v-tooltip v-else top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="red"
                dark
                class="rounded"
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="ml-2">
                  mdi-information-outline
                </v-icon>
                رد شده
              </v-chip>
            </template>
            <span>{{ row.status_desc }}</span>
          </v-tooltip>
        </td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import ProductsModal from "@/components/Panel/ProductsModal";
import CustomInput from "../../../../components/CustomInput.vue";
import VFormBase from "vuetify-form-base";

export default {
  created() {
    this.GetList();
    this.getSellers();
    this.getCategories();
  },
  components: {
    MyDialog,
    MyDataTable,
    ProductsModal,
    CustomInput,
    VFormBase,
  },
  methods: {
    search() {
      this.$router.push({
        path: "/productsmanager",
        query: {
          user_id: this.model.user_id || undefined,
          name: this.model.name || undefined,
          category: this.model.category || undefined,
        },
      });
      this.GetList();
    },
    GetList() {
      if (!this.loading) this.loading = true;

      if (
        this.$route.query.name ||
        this.$route.query.user_id ||
        this.$route.query.category
      )
        MyAxios.get("/products/admin/filter", {
          params: {
            page: this.$route.query?.page || 1,
            conditions: {
              user_id: this.$route.query.user_id || undefined,
              name: this.$route.query.name || undefined,
              category: this.$route.query.category || undefined,
            },
          },
        })
          .then((response) => {
            this.loading = false;
            this.items = response.data.data;
            this.pagesLength = response.data.last_page;
            console.log(this.model);
          })
          .catch((error) => {
            this.loading = false;
          });
      else
        MyAxios.get("/products/index", {
          params: {
            page: this.$route.query?.page || 1,
            ...this.pathParams,
          },
        })
          .then((response) => {
            this.loading = false;
            this.items = response.data.data;
            this.pagesLength = response.data.last_page;
          })
          .catch((error) => {
            this.loading = false;
          });
    },
    getSellers() {
      MyAxios.get("/user/list/vendor", {
        params: {
          noPaginate: true,
          with: "seller",
        },
      })
        .then((response) => {
          console.log(response);
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: [
              {
                id: 1,
                value: "ادمین",
              },
              ...response.data.map((val) => ({
                id: val.id,
                value:
                  val.first_name +
                  " " +
                  val.last_name +
                  (val.seller && val.seller?.store_name
                    ? " (" + val.seller?.store_name + ")"
                    : ""),
              })),
            ],
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getCategories() {
      MyAxios.get("/categories/index", {
        params: {
          noPaginate: true,
          conditions: {
            parent_id: 7,
          },
        },
      })
        .then((response) => {
          this.schema.category = {
            ...this.schema.category,
            loading: false,
            items: response.data.reduce(
              (arr, val) => [...arr, { ...val, children: [] }, ...val.children],
              []
            ),
          };
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/productsmanager/edit/" + item.slug,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/products/${item.slug}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.productsModal.dialog = true;
    },
    updateItems(items) {
      this.items = items;
    },
    getSpecificList(status = undefined) {
      this.pathParams.conditions.status = status;
      console.log(this.pathParams);
      this.GetList();
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      pagesLength: 1,
      path: "/products/index",
      pathParams: {
        with: "author",
        conditions: {},
      },
      headers: [
        {
          text: "نام فایل",
          align: "start",
          value: "name",
        },
        { text: "قرار دهنده", value: "author_id" },
        { text: "قیمت", value: "price" },
        { text: "تعداد بازدید", value: "viewed" },
        { text: "وضعیت", value: "status" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
      model: {
        user_id: +this.$route.query.user_id || 0,
        name: this.$route.query.name || "",
        category: +this.$route.query.category || 0,
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام فایل",
          required: true,
        },
        user_id: {
          type: "CustomInput",
          label: "نام فروشنده",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          loading: true,
          clearable: true,
        },
        category: {
          type: "CustomInput",
          label: "دسته بندی",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "name",
          clearable: true,
          loading: true,
        },
      },
    };
  },
};
</script>

<style></style>
